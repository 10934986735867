<template>
    <div class="lk_card activePolicies">
        <b-spinner v-if="loadingPdf" class="spinner-border-sm"/>
        <div class="des-991" :class="{'will_be_activated': willBeActivated}">
            <div class="lk_card__body">
                <div class="lk_card__body-top"
                     :class="{ insured: !userIsPolicyOwner, owner: userIsPolicyOwner }"
                >
                    <div class="position-relative">
                        <div class="lk_card__block lk_card__block-top">
                            <h3 class="lk_card-calc">{{ $t(productName) }}</h3>
                            <h4 class="lk_card-subName"></h4>
                        </div>
                        <div v-if="userIsPolicyOwner || operatorView" class="dropdown-wrapper">
                            <b-dropdown
                                v-if="showDropdown"
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="menu-wrapper">
                                <template #button-content>
                                    <div class="menu"></div>
                                </template>
                                <b-dropdown-item
                                    v-if="showDropdownDownload"
                                    @click="downloadCertificate(false, canGenerateCertificate)"
                                >
                                    <div class="card-top-menu-item">
                                        <div class="nui-icon nui-icon-download"></div>
                                        <span>{{ $t('Download') }}</span>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="showDropdownProlong"
                                    @click="$router.push({name: calculatorLink, params: { policy: policy , isProlong: true}})"
                                >
                                    <div class="card-top-menu-item">
                                        <div class="nui-icon nui-icon-prolong"></div>
                                        <span>{{ $t('Prolong') }}</span>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="showDropdownChangeRules"
                                    @click="openChangePolicyModal({global_id:policy.global_id,iin:policy.iin_bin})"
                                >
                                    <div class="card-top-menu-item">
                                        <div class="nui-icon nui-icon-change-rules"></div>
                                        <span>{{ $t('CHANGE_POLICY_RULES') }}</span>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
                <div class="lk_card__body-main">
                    <ul class="list">
                        <li class="item" v-if="(userIsPolicyOwner || operatorView) && showSwitcher">
                            <div class="prolong-row">
                                <b-form-checkbox @change="switcherHandler" v-model="prolongEveryMonth"
                                                 name="check-button" switch>
                                </b-form-checkbox>
                                <template v-if="showSwitcherType==='OGPO'">
                                    <div class="prolong-label">{{ $t('AUTO_PROLONG') }}</div>
                                    <div class="info-icon mob-center"
                                         :aria-label="$t('AUTO_PROLONG_WITH_DEFINITION_OGPO')"
                                         data-cooltipz-size="medium"
                                         data-cooltipz-dir="bottom">
                                        <div class="question-mark"></div>
                                    </div>
                                </template>
                                <template v-else-if="showSwitcherType==='IMUSHESTVO'">
                                    <div class="prolong-label">{{ $t('AUTO_PROLONG') }}</div>
                                    <div class="info-icon mob-center"
                                         :aria-label="$t('AUTO_PROLONG_WITH_DEFINITION')"
                                         data-cooltipz-size="medium"
                                         data-cooltipz-dir="bottom">
                                        <div class="question-mark"></div>
                                    </div>
                                </template>
                            </div>
                            <!--                            <AutoProlongConfirm @updateAutoProlong="updateAutoProlong" :flag="prolongEveryMonth"/>-->
                            <!--                            <AutoProlongInfo />-->
                            <span class="prolong-error">{{ prolongEveryMonthError }}</span>
                        </li>
                        <div v-if="!policyIsOST">
                            <li v-if="!userIsPolicyOwner" class="item"
                                :class="{'item-second': userIsPolicyOwner && showSwitcher}"
                            >
                                <h4 class="card-title">{{ $t('You are insured') }}</h4>
                            </li>
                            <li v-if="userIsPolicyOwner" class="item"
                                :class="{'item-second': userIsPolicyOwner && showSwitcher}"
                            >
                                <h4 class="card-title">{{ $t('You are owner') }}</h4>
                            </li>
                        </div>

                        <li class="item" v-if="policy.global_id">
                            <h4 class="card-title">{{ $t('Policy number') }}</h4>
                            <p class="card-value">{{ policy.global_id }}</p>
                        </li>
                        <li v-if="policy.details.cars.length > 0" class="item">
                            <h4 class="card-title" v-html="$t('Mark, model, car number')"></h4>
                            <ul class="cars-list">
                                <li v-for="car in policy.details.cars" :key="car.id">
                                    {{ car.mark }} {{ car.model }}, {{ car.born }}, {{ car.reg_number }}
                                </li>
                            </ul>
                        </li>
                        <li class="item" v-if="userIsPolicyOwner && policy.details.drivers.length > 0">
                            <h4 class="card-title">{{ $t('List of insured') }}</h4>
                            <div v-for="driver in policy.details.drivers" :key="driver.id">
                                <p class="card-value">
                                    {{ stringHelper.capitalize(driver.name, true) }}
                                </p>
                                <a v-if="showMedicalHelpLink"
                                   :href="medicalHelpLink(driver.iin_bin, driver.name_eng)"
                                   class="medical-help-link"
                                   target="_blank">
                                    {{ $t('Get medical help') }}
                                </a>
                            </div>
                        </li>
                        <li v-if="
                            (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                            || (policyOwner && !policy.details.drivers.length)
                        " class="item">
                            <h4 class="card-title">{{ $t('INS_AGENT') }}</h4>
                            <div>
                                <p class="card-value" v-if="policyOwner">
                                    {{ stringHelper.capitalize(policyOwner.nameEng ? policyOwner.nameEng :policyOwner.name, true) }}
                                </p>
                            </div>
                        </li>
                        <li v-if="
                            (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                            || (policyInsured && !policy.details.drivers.length)
                        " class="item">
                            <h4 class="card-title">{{ $t('INSURED') }}</h4>
                            <div>
                                <p class="card-value" v-if="policyInsured">
                                    {{ stringHelper.capitalize(policyInsured.nameEng ? policyInsured.nameEng :policyInsured.name, true) }}
                                </p>
                            </div>
                        </li>
                        <li v-if="userIsPolicyOwner || operatorView" class="item">
                            <h4 class="card-title">{{ $t('Insurance premium') }}</h4>
                            <p class="card-value">{{ Number(policy.contract_premium).toLocaleString() }} тг.</p>
                        </li>
                        <template v-if="userIsPolicyOwner && calculatorLink && is_reset">
                            <li class="item">
                                <h4 class="card-title">{{ $t('SUM_DOPLATA') }}</h4>
                                <p class="card-value">{{ Number(policy.contract_paid_premium).toLocaleString() }}
                                    тг.</p>
                            </li>
                            <li class="item">
                                <h4 class="card-title">{{ $t('RE_REGISTERED_FROM') }}</h4>
                                <p class="card-value">{{ policy.old_global_id }}</p>
                            </li>
                        </template>
                        <li class="item">
                            <h4 class="card-title">{{ $t('Validity period') }}</h4>
                            <p class="card-text-o">
                                {{ policy.begin_date }} - {{ policy.end_date }}
                            </p>
                            <a v-if="(userIsPolicyOwner || operatorView) && isExpiring && calculatorLink"
                               class="prolong-btn"
                               @click="$router.push({name:  calculatorLink, params: { policy: policy , isProlong: true}})">
                                {{ $t('Prolong') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="lk_card-footer" v-if="(userIsPolicyOwner || operatorView) && calculatorLink && showResetButton">
                <a class="lk_card-footer-link"
                   @click="resetButtonHandler">
                    {{ $t('Reset') }}
                </a>
            </div>

            <div class="lk_card-footer" v-if="willBeActivated">
                <span class="lk_card-footer-text">
                    {{ $t('The policy will become active from') }} {{ policy.begin_date }}
                </span>
            </div>
            <template v-else>
                <div class="lk_card-footer" v-if="showAccidentApplicationButton">
                    <a class="lk_card-footer-link"
                       @click="onChatOperator"
                    >
                        {{$t('INSURANCE_EVENT_REQUEST')}}
                    </a>
                </div>
            </template>
        </div>
        <div class="mob-991" :class="{'will_be_activated': willBeActivated}">
            <div class="lk_card__body-top"
                 :class="{ insured: !userIsPolicyOwner, owner: userIsPolicyOwner }"
            >
                <div class="position-relative">
                    <div class="lk_card__block">
                        <h3 class="lk_card-calc">{{ $t(productName) }}</h3>
                        <h4 class="lk_card-subName"></h4>
                    </div>
                    <div v-if="userIsPolicyOwner" class="dropdown-wrapper">
                        <b-dropdown
                            v-if="showDropdown"
                            variant="link" right
                            toggle-class="text-decoration-none"
                            no-caret
                            class="menu-wrapper">
                            <template #button-content>
                                <div class="menu"></div>
                            </template>
                            <b-dropdown-item
                                v-if="showDropdownDownload"
                                @click="downloadCertificate(false, canGenerateCertificate)"
                            >
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-download"></div>
                                    <span>{{ $t('Download') }}</span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="showDropdownProlong"
                                @click="$router.push({name: calculatorLink, params: { policy: policy, isProlong: true }})"
                            >
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-prolong"></div>
                                    <span>{{ $t('Prolong') }}</span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="showDropdownChangeRules"
                                @click="openChangePolicyModal({global_id:policy.global_id,iin:policy.iin_bin})"
                            >
                                <div class="card-top-menu-item">
                                    <div class="nui-icon nui-icon-change-rules"></div>
                                    <span>{{ $t('CHANGE_POLICY_RULES') }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div class="lk_card__body" >
                <div class="lk_card__body-main">
                    <div class="list mob-991">
                        <div class="item" v-if="(userIsPolicyOwner || operatorView) && showSwitcher">
                            <div class="prolong-row">
                                <b-form-checkbox @change="switcherHandler" v-model="prolongEveryMonth"
                                                 name="check-button" switch>
                                </b-form-checkbox>
                                <template v-if="showSwitcherType=='OGPO'">
                                    <div class="prolong-label">{{ $t('AUTO_PROLONG') }}</div>
                                    <div class="info-icon mob-center"
                                         :aria-label="$t('AUTO_PROLONG_WITH_DEFINITION_OGPO')"
                                         data-cooltipz-size="medium"
                                         data-cooltipz-dir="bottom">
                                        <div class="question-mark"></div>
                                    </div>
                                </template>
                                <template v-else-if="showSwitcherType=='IMUSHESTVO'">
                                    <div class="prolong-label">{{ $t('AUTO_PROLONG') }}</div>
                                    <div class="info-icon mob-center"
                                         :aria-label="$t('AUTO_PROLONG_WITH_DEFINITION')"
                                         data-cooltipz-size="medium"
                                         data-cooltipz-dir="bottom">
                                        <div class="question-mark"></div>
                                    </div>
                                </template>
                            </div>
                            <span class="prolong-error">{{ prolongEveryMonthError }}</span>
                        </div>
                        <div v-if="!policyIsOST">
                            <div v-if="!userIsPolicyOwner" class="item">
                                <h4 class="card-title">{{ $t('You are insured') }}</h4>
                            </div>
                            <div v-if="userIsPolicyOwner" class="item">
                                <h4 class="card-title">{{ $t('You are owner') }}</h4>
                            </div>
                        </div>
                        <div class="item">
                            <h4 class="card-title" v-if="policy.global_id">{{ $t('Policy number') }}</h4>
                            <p class="card-value">{{ policy.global_id }}</p>
                        </div>
                        <b-collapse v-model="visible" class="block-hidden">
                            <div v-if="policy.details.cars.length > 0" class="item">
                                <h4 class="card-title" v-html="$t('Mark, model, car number')"></h4>
                                <ul class="cars-list">
                                    <li v-for="car in policy.details.cars" :key="car.id">
                                        {{ car.mark }} {{ car.model }}, {{ car.born }}, {{ car.reg_number }}
                                    </li>
                                </ul>
                            </div>
                            <div class="item" v-if="(userIsPolicyOwner || operatorView) && policy.details.drivers.length > 0">
                                <h4 class="card-title">{{ $t('List of insured') }}</h4>
                                <div v-for="driver in policy.details.drivers" :key="driver.id">
                                    <p class="card-value">
                                        {{ stringHelper.capitalize(driver.name, true) }}
                                    </p>
                                    <a v-if="showMedicalHelpLink"
                                       :href="medicalHelpLink(driver.iin_bin, driver.name_eng)"
                                       class="medical-help-link"
                                       target="_blank">
                                        {{ $t('Get medical help') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item" v-if="
                                (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                                || (policyOwner && !policy.details.drivers.length)
                            ">
                                <h4 class="card-title">{{ $t('INS_AGENT') }}</h4>
                                <div>
                                    <p class="card-value" v-if="policyOwner">
                                        {{ stringHelper.capitalize(policyOwner.nameEng ? policyOwner.nameEng :policyOwner.name, true) }}
                                    </p>
                                </div>
                            </div>
                            <div class="item" v-if="
                               (!userIsPolicyOwner || (!policyHasOwner && operatorView))
                                || (policyInsured && !policy.details.drivers.length)
                            ">
                                <h4 class="card-title">{{ $t('INSURED') }}</h4>
                                <div>
                                    <p class="card-value" v-if="policyInsured">
                                        {{ stringHelper.capitalize(policyInsured.nameEng ? policyInsured.nameEng :policyInsured.name, true) }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="userIsPolicyOwner || operatorView" class="item">
                                <h4 class="card-title">{{ $t('Insurance premium') }}</h4>
                                <p class="card-value">{{ Number(policy.contract_premium).toLocaleString() }} тг.</p>
                            </div>
                            <template v-if="(userIsPolicyOwner || operatorView) && calculatorLink && is_reset">
                                <div class="item">
                                    <h4 class="card-title">{{ $t('SUM_DOPLATA') }}</h4>
                                    <p class="card-value">{{ Number(policy.contract_paid_premium).toLocaleString() }}
                                        тг.</p>
                                </div>
                                <div class="item">
                                    <h4 class="card-title">{{ $t('RE_REGISTERED_FROM') }}</h4>
                                    <p class="card-value">{{ policy.old_global_id }}</p>
                                </div>
                            </template>
                            <div class="item">
                                <h4 class="card-title">{{ $t('Validity period') }}</h4>
                                <p class="card-text-o">
                                    {{ policy.begin_date }} - {{ policy.end_date }}
                                </p>
                            </div>
                        </b-collapse>
                        <div :class="visible ? null : 'collapsed'" class="card-btn collapse-btn" @click="openBlock" ref="cardBtn">
                            <div class="btn-text">{{ openText ? 'Свернуть' : 'Развернуть' }}</div>
                            <div v-bind:class="{ open: openText }" class="icon"></div>
                        </div>
                        <a v-if="(userIsPolicyOwner || operatorView) && isExpiring && calculatorLink"
                           class="prolong-btn"
                           @click="$router.push({name:  calculatorLink, params: { policy: policy , isProlong: true}})">
                            {{ $t('Prolong') }}
                        </a>
                    </div>
                    <div class="lk_card-footer" v-if="(userIsPolicyOwner || operatorView) && calculatorLink && showResetButton">
                        <a class="lk_card-footer-link"
                           @click="resetButtonHandler">
                            {{ $t('Reset') }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="lk_card-footer" v-if="willBeActivated">
                <span class="lk_card-footer-text">
                    {{ $t('The policy will become active from') }} {{ policy.begin_date }}
                </span>
            </div>
            <template v-else>
                <div class="lk_card-footer" v-if="showAccidentApplicationButton">
                    <a class="lk_card-footer-link"
                       @click="onChatOperator"
                    >
                        {{$t('INSURANCE_EVENT_REQUEST')}}
                    </a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {policiesCardMixin} from "@/mixins/contracts/PoliciesCardMixin";

export default {
    name: 'PoliciesCardActive',
    mixins: [policiesCardMixin],
    props:{
        operatorView: {
            required: false,
            type: Boolean,
            default: () => false
        },
    },
    data: () => {
        return {
            visible: false,
            openText: false,
            is_reset: false,
            loadingPdf: false,
            checked: true,
            prolongEveryMonth: false,
            prolongEveryMonthError: '',
            disableAutoProlongSwitcher: false
        }
    },
    methods: {
        ...mapActions({
            setAlertText: 'modalOptions/setAlertText',
            openChangePolicyModal: 'modalOptions/openChangePolicyModal',
        }),
        onChatOperator  ()  {
            this.$store.dispatch('chat/startEmptyChat')
        },
        openBlock() {
            this.visible = !this.visible
            this.openText = !this.openText;
        },
        medicalHelpLink(iin, name) {
            return `https://api.whatsapp.com/send/?phone=905301766500&text=Insurance+Company:+Nomad.%0aMy+Policy+Number:+${this.policy.global_id}.%0aMy+Name+Surname:+${name}.%0aPlease+click+send+to+start`
        },
        toggleSwitcher() {
            this.prolongEveryMonth = !this.prolongEveryMonth
        },
        switcherHandler() {
            this.updateAutoProlong(true)
        },
        updateAutoProlong(sign) {
            if (!sign) { // если нажал "нет" при подтверждении в попапе
                this.toggleSwitcher()
                return
            }
            // this.disableAutoProlongSwitcher = true
            this.prolongEveryMonthError = ''
            let iblockIdsArr = []
            switch (+this.policy.iblock_id) {
                case this.constants.iblockIds.SITE.IMUSHESTVO:
                case this.constants.iblockIds.APP.IMUSHESTVO:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.IMUSHESTVO,
                        this.constants.iblockIds.APP.IMUSHESTVO,
                    ]
                    break
                case this.constants.iblockIds.SITE.OGPO:
                case this.constants.iblockIds.APP.OGPO:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.OGPO,
                        this.constants.iblockIds.APP.OGPO_GATEWAY,
                    ]
                    break
                case this.constants.iblockIds.SITE.ONT:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.ONT,
                    ]
                    break
                case this.constants.iblockIds.SITE.KASKO_DTP:
                case this.constants.iblockIds.APP.KASKO_DTP:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.KASKO_DTP,
                        this.constants.iblockIds.APP.KASKO_DTP,
                    ]
                    break
                case this.constants.iblockIds.SITE.COMFORT:
                case this.constants.iblockIds.APP.COMFORT:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.COMFORT,
                        this.constants.iblockIds.APP.COMFORT,
                    ]
                    break
            }
            switch (+this.policy.product_id) {
                case this.constants.productIds.imushestvo:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.IMUSHESTVO,
                        this.constants.iblockIds.APP.IMUSHESTVO,
                    ]
                    break
                case this.constants.productIds.ogpo:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.OGPO,
                        this.constants.iblockIds.APP.OGPO_GATEWAY,
                    ]
                    break
                case this.constants.productIds.ont:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.ONT,
                    ]
                    break
                case this.constants.productIds.kasko_dtp:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.KASKO_DTP,
                        this.constants.iblockIds.APP.KASKO_DTP,
                    ]
                    break
                case this.constants.productIds.comfort:
                    iblockIdsArr = [
                        this.constants.iblockIds.SITE.COMFORT,
                        this.constants.iblockIds.APP.COMFORT,
                    ]
                    break
            }
            let self = this
            this.$axios.post('/contracts/update/prolong', {
                flag: this.prolongEveryMonth,
                // flag: false,
                global_id: this.policy.global_id,
                iblock_ids: iblockIdsArr
            }).then(response => {
                if (!response.data.success) {
                    let mess = window.mess;
                    if (self.prolongEveryMonth)
                        self.prolongEveryMonthError = mess.FAIL_ON_AUTO_PROLONG
                    else
                        self.prolongEveryMonthError = mess.FAIL_OFF_AUTO_PROLONG
                    // self.disableAutoProlongSwitcher = false
                    setTimeout(self.toggleSwitcher, 50)
                } else {
                    self.prolongEveryMonthError = ''
                }
            })
        },
        resetButtonHandler() {
            this.loadingPdf = true
            this.$axios.get(`/contracts/is-acting/${this.policy.global_id}`).then(response => {
                if (response.data.success) {
                    this.$router.push({name: this.calculatorLink, query:{
                            globalId:this.policy.global_id,
                            isReset:true
                        }
                    })
                } else {
                    //сбрасываем кеш на беке ПОСЛЕ обновляем полисы на фронте, поднимаем попап что полис не действует
                    this.$emit('fetchPolicies', true)
                    this.setAlertText(this.$t('Policy is not acting'))
                    this.$bvModal.show('alert-info-text')
                }
            }).catch(e => {
                // поднимаем попап что с сервером что то не так
                this.setAlertText(e.response.data.message)
                this.$bvModal.show('alert-info-text')
            }).finally(() => {
                this.loadingPdf = false
            })
        },
    },
    computed: {
        ...mapGetters('auth', ['user']),

        // (флаг) показ выпадающего меню
        showDropdown(){
          return this.canGenerateCertificate || this.policy.pdf_link || (this.isExpiring && this.calculatorLink)
        },
        // (флаг) Показ в меню кнопки скачать сертификат
        showDropdownDownload(){
          return this.canGenerateCertificate || this.policy.pdf_link
        },
        // (флаг) Показ в меню кнопки продлить
        showDropdownProlong(){
          // блокируем продление, если полис длиной менее 360 дней
          if (
              this.calculatorsMapping.dataLab !== undefined &&
              (this.calculatorsMapping.dataLab[this.policy.product_id] === 'ACCIDENT' ||
              this.calculatorsMapping.dataLab[this.policy.product_id] === 'ACCIDENT_SPORT')
          ) {
            let days = this.dateHelper.daysBetween(this.policy.begin_date, this.policy.end_date)
            if (+days < 360) return false
          }
          return this.isExpiring && this.calculatorLink
        },
        // (флаг) Показ в меню кнопки переоформление (кроме ОГПО)
        showDropdownChangeRules(){
          return this.policy.product_id!=='1'
        },
        isExpiring() {
            let daysLeft = this.dateHelper.daysLeft(this.policy.end_date)
            return daysLeft <= 30 && daysLeft > 0
        },
        calculatorLink() {
            if (Object.keys(this.calculatorsMapping).length === 0)
                return ''
            if (this.policy.product_id){
                return this.calculatorsMapping.dataLab[this.policy.product_id]
            }
            else if (this.policy.iblock_id){
                return this.calculatorsMapping.site[this.policy.iblock_id]
            }
            return ''
        },
        willBeActivated() {
            return parseInt(this.policy.will_be_activated) === 1
        },
        showResetButton() {
            let ogpoNames = [
                'Обязательное страхование гражданско-правовой ответственности владельцев транспортных средств',
                'ОГПО'
            ]
            return this.dateHelper.getTimestamp() >= this.dateHelper.getTimestamp(this.policy.begin_date)
                && this.dateHelper.getTimestamp() <= this.dateHelper.getTimestamp(this.policy.end_date)
                && ogpoNames.includes(this.policy.product_name);
        },
        showMedicalHelpLink() {
            let productIds = this.constants.productIds

            return [productIds.ost].includes(parseInt(this.policy.product_id))
            //return false
        },
        productName() {
            return this.policy.product_name.replaceAll('"', '')
        },
        canGenerateCertificate() {
            if (!this.policy.global_id)
                return false;

            if (parseInt(this.policy.product_id) !== this.constants.productIds.ogpo) {
                return false;
            }

            // для ОГПО логика ниже
            let can = true;
            if (!this.policy.pdf_link) {
                for (const driver of this.policy.details.drivers) {
                    if (!driver.natural_person) {
                        can = false
                        break
                    }
                }

                if (can) {
                    for (const car of this.policy.details.cars) {
                        if (car.mark === undefined) {
                            can = false
                            break
                        }
                    }
                }
            } else {
                can = false;
            }
            return can
        },
        // показ кнопок под карточкой
        showSwitcher() {
            if (!this.policy.from_gtw_59)
                return false

            return +this.policy.iblock_id === this.constants.iblockIds.SITE.IMUSHESTVO
                || +this.policy.iblock_id === this.constants.iblockIds.APP.IMUSHESTVO
                || +this.policy.product_id === this.constants.productIds.imushestvo

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.OGPO
                || +this.policy.iblock_id === this.constants.iblockIds.APP.OGPO_GATEWAY
                || +this.policy.product_id === this.constants.productIds.ogpo

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.ONT
                || +this.policy.product_id === this.constants.productIds.ont

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.KASKO_DTP
                || +this.policy.iblock_id === this.constants.iblockIds.APP.KASKO_DTP
                || +this.policy.product_id === this.constants.productIds.kasko_dtp

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.COMFORT
                || +this.policy.iblock_id === this.constants.iblockIds.APP.COMFORT
                || +this.policy.product_id === this.constants.productIds.comfort

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.ACCIDENT
                || +this.policy.iblock_id === this.constants.iblockIds.APP.ACCIDENT
                || +this.policy.product_id === this.constants.productIds.accident

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.ACCIDENT_SPORT
                || +this.policy.iblock_id === this.constants.iblockIds.APP.ACCIDENT_SPORT
                || +this.policy.product_id === this.constants.productIds.accident_sport
        },
        // todo: надо исправить тип, не огпо и имущество - а годовой и месячный
        showSwitcherType() {
            if (!this.policy.from_gtw_59)
                return false

            if (
                +this.policy.iblock_id === this.constants.iblockIds.SITE.IMUSHESTVO
                || +this.policy.iblock_id === this.constants.iblockIds.APP.IMUSHESTVO
                || +this.policy.product_id === this.constants.productIds.imushestvo

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.COMFORT
                || +this.policy.iblock_id === this.constants.iblockIds.APP.COMFORT
                || +this.policy.product_id === this.constants.productIds.comfort
            ) {
                return 'IMUSHESTVO'
            }
            if (+this.policy.iblock_id === this.constants.iblockIds.SITE.OGPO
                || +this.policy.iblock_id === this.constants.iblockIds.APP.OGPO_GATEWAY
                || +this.policy.product_id === this.constants.productIds.ogpo

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.ONT
                || +this.policy.product_id === this.constants.productIds.ont

                || +this.policy.iblock_id === this.constants.iblockIds.SITE.KASKO_DTP
                || +this.policy.iblock_id === this.constants.iblockIds.APP.KASKO_DTP
                || +this.policy.product_id === this.constants.productIds.kasko_dtp) {
                return 'OGPO'
            }
            return false
        },
        showAccidentApplicationButton() {
            return Object.values(this.constants.productIds).includes(parseInt(this.policy.product_id))
        },
        userIsPolicyOwner() {
            return this.policyHasOwner || this.policyIsOST
        },
        policyIsOST() {
            let productIds = this.constants.productIds
            let id = this?.policy?.product_id
            if (id) {
                id = Number(id)
            }

            return id === productIds.ost
        },
        policyHasOwner() {
            if(this.policy?.is_owner) {
                return parseInt(this.policy.is_owner, 10) === 1
            }
            return false
        },
        policyOwner() {
            return this.policy?.details?.owner
        },
        policyInsured() {
            return this.policy?.details?.insured
        },
    },
    mounted() {
        if (this.policy.is_reset === true)
            this.is_reset = true
    }
}
</script>

<style lang="scss">
.activePolicies{
    position: relative;
}
.spinner-border.spinner-border-sm{
    margin: 0;
    position: absolute;
    top: 24px;
    right: 15px;
    color: #999999;
    border-width: 0.2rem !important;
}
.will_be_activated{
    .lk_card-footer{
        span.lk_card-footer-text, span.lk_card-footer-text:hover {
            color: #73758C;
        }
    }
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: #FFFFFF66;
        pointer-events: none;
    }
}
.lk_card{
    width: 190px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
    border-radius: 10px;
    border: 1px solid #E8EDF5;
    flex-shrink: 0;
    .item.item-second {
        margin-top: 10px;
    }
}

.lk_card__body-top {
    padding: 15px;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid $lightGrey;

    .position-relative{
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 991px) {
            padding: 0 15px;
        }

        .dropdown-wrapper {
            margin-top: -1px;
            width: auto;
            display: flex;
            align-items: center;
        }
    }

    &.owner {
        background-color: #FFDCBF99;
    }
    &.insured {
        background-color: #E8EDF5;
        .lk_card-calc {
            color: #94B2E4;
        }
    }
}

.lk_card__body{
    padding: 0 0 20px;

    .medical-help-link{
        font-size: 12px;
        color: $orange;
        background: url('~@/assets/images/medical-help.svg') left no-repeat;
        padding-left: 20px;

        &:hover{
            color: $hoverOrange;
        }
    }
}

.lk_card-footer{
    border-top: 1px solid $lightGrey;

    .lk_card-footer-link, span.lk_card-footer-text{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 10px;
        font-weight: 400;
        text-align: center;
        width: 100%;
        font-size: 14px;
        line-height: 127.1%;
        color: $orange !important; // Перетирает bootstrap;
        cursor: pointer;

        &:hover{
            color: $hoverOrange;
        }
    }

    span.lk_card-footer-text{
        cursor: default;
    }
}

.card-text-o{
    margin-top: 5px;
    font-weight: 400;
    width: 100%;
    font-size: 14px;
    line-height: 127.1%;
    color: $orange;
}

.lk_card-calc{
    padding: 2px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 127.1%;
    color: $orange;
}

.lk_card-subName{
    position: absolute;
    bottom: -16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 127.1%;
    color: $orange;
}

.lk_card .menu{
    padding: 5px;
    width: 15px;
    height: 3px;
    background: url('~@/assets/images/menu.svg') center / auto no-repeat;
    cursor: pointer;
}

.lk_card__body-main{
    padding: 20px 15px 0;
}

.lk_card{

    a.prolong-btn{
        display: block;
        cursor: pointer;
        color: #fff;
        background: $orange;
        width: 100%;
        text-align: center;
        border-radius: 50px;
        margin-top: 16px;
        padding: 6px 0;
        font-size: 14px;
        font-weight: normal;

        &:hover{
            color: #fff;
            background: $hoverOrange;
        }
    }

    .item{
        margin-top: 20px;

        ul.cars-list{
            margin-top: 10px;

            li{
                font-size: 14px;
                margin-bottom: 7px;
                padding-left: 10px;
                position: relative;

                &:before{
                    position: absolute;
                    content: "\2022 ";
                    left: 0;
                    color: #8A94A6;
                }
            }
        }

        &:last-child{
            margin-top: 9px;
            padding-top: 15px;
            border-top: 1px solid $lightGrey;
        }

        &:first-child{
            margin-top: 0;
        }
        .prolong-error{
            color: red;
            font-size: 10px;
            display: block;
        }
        #prolong-checkbox, #prolong-checkbox-mob{
            float: right;
        }
    }

    .card-title{
        margin-bottom: 0;
        font-weight: 600;
        font-size: 13px;
        line-height: 127.1%;
        color: #8A94A6;
    }

    .card-value{
        margin-top: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 127.1%;
        color: #000000;
    }
}

.menu-wrapper{
    .btn{
        margin-right: -5px;
        padding: 5px;

        &:focus-visible{
            outline: 0;
        }
    }

    .dropdown-menu{
        min-width: 1px;
        padding: 9px 10px;
        background: #FFFFFF;
        border: 1px solid #EFF2F7;
        box-shadow: 0 4px 5px rgba(115, 117, 140, 0.15);
        border-radius: 5px;

        .dropdown-item{
            padding: 8px;
            font-size: 12px;
            line-height: 127.1%;
            color: #000000;

            span{
                margin-left: 14px;
            }

            img{
                vertical-align: top;
            }
        }
    }

    .dropdown-item:focus, .dropdown-item:hover{
        color: $orange;
        background-color: transparent;

        path{
            fill: $orange;
        }
    }

    .btn-check:focus + .btn, .btn:focus{
        outline: 0;
        box-shadow: none;
    }
}

.lk_card__block{
    position: relative;
}

.collapse-btn {
    &.card-btn {
        padding: 15px 0 5px;
        .btn-text {
            font-size: 14px;
        }
    }
}

.card-top-menu-item {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    .nui-icon {
        font-size: 18px;
    }
    span {
        font-size: 12px;
    }
}

@media (max-width: 991px){
    .spinner-border.spinner-border-sm{
        right: 21px;
    }
    a.prolong-btn{
        margin-top: 10px;
    }
    .lk_card{
        width: 82%;
        max-width: 450px;

        .card-title{
            font-size: calc(13px + 9 * ((100vw - 320px) / (768 - 320)));
        }

        .card-value{
            font-size: calc(14px + 10 * ((100vw - 320px) / (768 - 320)));
        }

        .menu{
            width: calc(15px + 11 * ((100vw - 320px) / (768 - 320)));
            height: calc(3px + 1 * ((100vw - 320px) / (768 - 320)));
            background-size: contain;
        }
        .item{
            label{
                width: calc(100% - 10px);
            }
        }
    }
    .lk_card__body{
        padding: 5px 20px 15px;
    }
    .lk_card__body-main {
        padding: 20px 0;
    }
    .block-hidden{
        margin-top: 14px;
    }
    .lk_card-calc{
        font-size: calc(14px + 10 * ((100vw - 320px) / (768 - 320)));
    }
    .lk_card-subName{
        font-size: calc(10px + 8 * ((100vw - 320px) / (768 - 320)));
        bottom: calc(-10px - 1.87vw);
    }
    .lk_card-footer-link{
        font-size: calc(13px + 9 * ((100vw - 320px) / (768 - 320)));
    }
    .lk_card__body-top{
        padding: 20px 0;
        border-bottom: 1px solid #D0D7E3;
    }
    .menu-wrapper .dropdown-menu .dropdown-item{
        font-size: calc(12px + 8 * ((100vw - 320px) / (768 - 320)));
    }
    .card-text-o{
        font-size: calc(14px + 10 * ((100vw - 320px) / (768 - 320)));
    }
}

.prolong-row{
    display: flex;
    margin-top: -12px;
    align-items: center;
    .prolong-label{
        font-weight: 400;
        font-size: 11px;
        color: #60627B;
        margin-bottom: 6px;
    }
    // switch checkbox
    .custom-control{
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
    }

    .custom-control-inline{
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 1rem;
    }

    .custom-control-input{
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
    }

    .custom-control-input:checked ~ .custom-control-label::before{
        color: #fff;
        border-color: #ef863a;
        background-color: #ef863a;
    }

    /*.custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }*/

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before{
        border-color: #aaacc9;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before{
        color: #fff;
        background-color: #fff;
        border-color: #aaacc9;
    }

    .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label{
        color: #ffffff;
    }

    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before{
        background-color: #ffffff;
    }

    .custom-control-label{
        position: relative;
        margin-bottom: 0;
        vertical-align: top;
    }

    .custom-control-label::before{
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: calc(1rem - 3px);
        pointer-events: none;
        content: "";
        background-color: #fff;
        border: #aaacc9 solid 1px;
    }

    .custom-control-label::after{
        position: absolute;
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
        background: no-repeat 50% / 50% 50%;
    }

    .custom-checkbox .custom-control-label::before{
        border-radius: 0.25rem;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }

    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before{
        border-color: #aaacc9;
        background-color: #aaacc9;
    }

    .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
    }

    .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before{
        background-color: #ffffff;
    }

    .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before{
        background-color: #ffffff;
    }

    .custom-radio .custom-control-label::before{
        border-radius: 50%;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before{
        background-color: #ffffff;
    }

    .custom-switch{
        padding-left: 2.25rem;
    }

    .custom-switch .custom-control-label::before{
        left: -2.25rem;
        width: 1.6rem;
        pointer-events: all;
        border-radius: 0.5rem;
    }

    .custom-switch .custom-control-label::after{
        top: calc(0.25rem + 1px);
        left: calc(-2.25rem - 1px);
        width: calc(1rem - 4px);
        height: calc(1rem - 5px);
        background-color: #fff;
        border-radius: 0.5rem;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        scale: 1.35;
        border: 1px solid #aaacc9;
    }

    @media (prefers-reduced-motion: reduce){
        .custom-switch .custom-control-label::after{
            transition: none;
        }
    }

    .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
        background-color: #fff;
        -webkit-transform: translateX(0.75rem);
        transform: translateX(0.75rem);
    }

    .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before{
        background-color: #ef863a;
    }
}
.question-mark{
    width: 11px;
    height: 11px;
    background: url('~@/assets/images/question.svg') no-repeat;
    background-size: contain;
    margin-left: 5px;
}
.custom-control-label{
    display: flex;
    align-items: center;
}
.info-icon{
    &.mob-center{
        margin-bottom: 6px;
    }
}
</style>
