<template>
    <main role="main" class="authorization">
        <b-container>
            <div class="authorization__content">
                <section class="forms-wrapper">
                    <div class="auth-logo">
                        <img src="../assets/images/logo__75-75.svg" alt="logo">
                    </div>
                    <h1 class="auth-title">{{ $t('Personal Account') }}</h1>

                    <div class="check-iin" v-if="showFirstStep">
                        <div class="input-block">
                            <label for="iin-input">{{ $t('Enter IIN') }}</label>
                            <the-mask v-model="form.iin"
                                      type="tel"
                                      :mask="['############']"
                                      @keyup.enter.native="checkProfileExists"
                                      id="iin-input"
                                      autofocus/>
                            <div class="error" v-if="errors.iin">
                                {{ $t(errors.iin) }}
                            </div>
                        </div>
                        <div class="auth-button">
                            <AppButton @click.native="checkProfileExists"
                                       :loading="loading" :title="$t('Next')"/>
                        </div>
                    </div>

                    <div class="enter-phone" v-if="showSecondStep">
                        <div class="input-block">
                            <label>Введите номер телефона</label>
                            <the-mask v-model="form.phone"
                                      type="tel"
                                      :mask="['+7(###)###-####']"
                                      @keyup.enter.native="kdpShow"
                                      autofocus/>
                            <div class="error" v-if="errors.phone_number">
                                {{ $t(errors.phone_number) }}
                            </div>
                        </div>
                        <div class="auth-button">
                            <AppButton @click.native="kdpShow"
                                       :loading="loading" :title="$t('Next')"/>
                        </div>
                    </div>
                    <div class="enter-phone" v-if="showPasswordReset">
                        <div class="input-block">
                            <label>Введите номер телефона</label>
                            <the-mask v-model="form.phone"
                                      type="tel"
                                      :mask="['+s(###)###-####']"
                                      :tokens="phoneToken"
                                      @keyup.enter.native="sendNewPassword"
                                      autofocus/>
                            <div class="error" v-if="errors.password">
                                {{ $t(errors.password) }}
                            </div>
                        </div>
                        <div class="auth-button">
                            <AppButton @click.native="sendNewPassword"
                                       :loading="timerEnabled" :title="$t('Next')" />
                        </div>
                    </div>

                    <div class="log-in" v-if="showThirdStep">
                        <div class="input-block">
                            <label for="password-input">{{ $t('Enter current password') }}</label>
                            <div class="password-wrapper">
                                <input :type="showPassword ? 'text' : 'password'" v-model="form.password"
                                       id="password-input"
                                       @keyup.enter="login"
                                       ref="password-input"
                                       class="password-input"/>
                                <div class="show-password" @click="togglePassword">
                                    <div v-if="!showPassword" class="icon eye-open show"></div>
                                    <div v-else class="icon eye-close show"></div>
                                </div>
                                <div class="error" v-if="errors.password">
                                    {{ $t(errors.password) }}
                                </div>
                            </div>
                        </div>
                        <div class="timer-block">
                            <p class="wait" v-if="timerEnabled">
                                Отправить новый пароль через <span>{{ timerCount }}</span> сек
                            </p>
                            <p class="get-new-password" :class="{'disabled': sendPasswordLoading}" v-else
                               @click.prevent="showPasswordResetForm">{{ $t('Get new password by SMS') }}</p>
                        </div>
                        <div class="auth-button">
                            <AppButton @click.native="login" :loading="loading" :title="$t('Log in')"/>
                        </div>
                    </div>
                </section>
            </div>
        </b-container>
        <b-modal
            id="confirmBtsModal"
            header-class="c-modal-header"
            modal-class="login-page-modal"
            hide-header
            hide-footer
            centered>
            <h3 v-if="!profileReRegistration" class="title" v-html="$t('Your IIN is not registered')"></h3>
            <h3 v-if="profileReRegistration" class="title" v-html=" $t('Your need pass Biometry to change Phone number')"></h3>
            <p class="text" v-html="$t('Biometric identification')"></p>
            <AppButton title="OK" @click.native="btsRedirect" />
        </b-modal>
        <b-modal
            v-model="showMessageModal"
            id="messageModal"
            header-class="c-modal-header"
            modal-class="login-page-modal"
            hide-header
            hide-footer
            centered>
            <p class="text" v-html="$t(message)"></p>
            <AppButton @click.native="showMessageModal = false; message = false" :title="'OK'"></AppButton>
        </b-modal>
        <b-modal
            id="kdpError"
            header-class="c-modal-header"
            modal-class="login-page-modal"
            hide-header
            hide-footer
            centered>
            <p class="text" v-html="$t(message)"></p>
            <AppButton @click.native="phoneEntered; $bvModal.hide('kdpError')" :title="'Продолжить регистрацию'"></AppButton>
        </b-modal>
        <b-modal
            v-model="showPasswordExpiredModal"
            id="passwordExpiredModal"
            header-class="c-modal-header"
            modal-class="login-page-modal"
            hide-header
            hide-footer
            centered>
            <h3 class="title" v-html="$t('We have sent an SMS with a verification code')"></h3>
            <p class="text" v-html="$t('This code will be the password to enter your personal account')"></p>
            <AppButton @click.native="showPasswordExpiredModal = false" :title="'OK'"></AppButton>
        </b-modal>
        <b-modal
            id="confirmVeriliveModal"
            header-class="c-modal-header"
            modal-class="login-page-modal"
            hide-header
            hide-footer
            centered>
            <h3 v-if="!profileReRegistration" class="title" v-html=" $t('Your IIN is not registered')"></h3>
            <h3 v-if="profileReRegistration" class="title" v-html=" $t('Your need pass Biometry to change Phone number')"></h3>
            <p class="text" v-html="$t('Biometric identification')"></p>
            <AppButton title="OK" @click.native="veriliveRedirect" />
        </b-modal>
    </main>
</template>

<script>
/* eslint-disable */
import AppButton from "@/components/AppButton";
import {TheMask} from 'vue-the-mask'
import {mapActions} from 'vuex'
import i18n from '../plugins/i18n'
import {Browser} from '@capacitor/browser'
import {echoInstance} from "@/plugins/echo";
import Cookies from "js-cookie";
import { Device } from '@capacitor/device';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';

const urlParams = new URLSearchParams(window.location.search);
function useRecaptcha (callback) {
  if (callback === undefined) return false
  //global captcha_code in header
  if(typeof(grecaptcha)==='undefined'){
    setTimeout(function(){
      useRecaptcha(callback)
    },200)
  }else{
    grecaptcha.ready(function () {
      grecaptcha.execute('6LeShLspAAAAAEwspeAcfRvqHfWjiYXVUUSVY0Ev', { action: 'submit' }).then(function (token) {
        callback(token)
      })
    })
    return true
  }
  return false
}

function initialState() {
    return {
        form: {
            iin: urlParams.get('iin') ? urlParams.get('iin') : null,
            phone: '+7 (',
            password: null,
        },
        loading: false,
        sendPasswordLoading: false,
        profileChecked: false,
        profileExists: false,
        profileReRegistration: false,
        btsRedirectUrl: false,
        phoneLogInConfirmed: false,
        showPasswordExpiredModal: false,
        showMessageModal: false,
        message: false,
        showPassword: false,
        timerEnabled: false,
        timerCount: 50,
        errors: {},
        bitrixUserId: false,
        showSecondStep: false,
        showPasswordReset: false,
        biometricSystem: '',
        phoneToken:{
            "s":{
                pattern: /^7$/,
            },
            "#" : {
                pattern: /\d/
            }
        }
    }
}

export default {
    name: 'LoginView',
    data: () => {
        return initialState()
    },
    metaInfo: {
        title: i18n.tc('Authorization'),
        titleTemplate: '%s | Nomad.kz'
    },
    components: {
        TheMask,
        AppButton
    },
    methods: {
        ...mapActions({
            signIn: 'auth/login',
            saveToken: 'auth/setToken',
            setIinForVerilive: 'verilive/setIin',
            setShowSecondStepOfLoginPage: 'verilive/setShowSecondStepOfLoginPage'
        }),
        async checkProfileExists() {
            if(this.form.iin.length !== 12) {
                return
            }

            if (!this.loading) {
                this.loading = true;

                let bitrixFail = {}
                try {
                    const btsResponse = await this.$axios.post('auth/check-profile-exists-bts', {
                        iin: this.form.iin
                    });

                    this.errors.iin = false

                    this.profileChecked = true
                    this.profileExists = btsResponse.data.exists
                    this.btsRedirectUrl = btsResponse.data.url
                    this.profileReRegistration = btsResponse.data?.reRegistration

                } catch (error) {
                    bitrixFail = error
                }


                if (!this.profileExists) {

                    try {
                        const bitrixResponse = await this.$axios.post('auth/check-profile-exists-bitrix', {
                            iin: this.form.iin
                        });

                        if (bitrixResponse.data?.exists === true) {

                            this.errors.iin = false
                            this.profileChecked = true
                            this.profileExists = bitrixResponse.data.exists
                            this.bitrixUserId = bitrixResponse.data.userId
                        }
                    } catch (e) {
                        if (bitrixFail && bitrixFail?.response?.data?.errors)
                            this.errors = bitrixFail.response.data.errors
                    }
                }
                if (!this.profileExists || this.profileReRegistration) {
                    this.profileChecked = false
                    if (this.biometricSystem === this.constants.verification.bts) {
                        if (typeof this.btsRedirectUrl !== 'undefined') {
                            this.$bvModal.show('confirmBtsModal');
                        }
                    } else if (this.biometricSystem === this.constants.verification.verilive) {
                        // starting verilive
                        this.$bvModal.show('confirmVeriliveModal');
                    }
                }
                this.loading = false
            }
        },
        async login() {
            if (this.loading)
                return

            this.loading = true

            let firebaseToken = null;
            let deviceId = null;
            let deviceInfo = null;

            if (!(Capacitor.getPlatform() === 'web')) {
                firebaseToken = this.mobileApp.isWebView() ? (await FCM.getToken()).token : null;
                deviceId = this.mobileApp.isWebView() ? (await Device.getId()).identifier : null;
                deviceInfo = this.mobileApp.isWebView() ? JSON.stringify((await Device.getInfo())) : null;
            }

            let successResponse = false
            this.$axios.post('auth/login', {
                iin: this.form.iin,
                password: this.form.password,
                is_mobile_app: this.mobileApp.isWebView(),
                firebase_token: firebaseToken,
                device_id: deviceId,
                device_info: deviceInfo
            }).then(response => {
                if (response.data.passwordUpdated) {
                    this.$bvModal.show('passwordExpiredModal');
                } else {
                    successResponse = true
                    this.saveToken(response.data.token)
                    this.signIn().finally(() => {
                        this.loading = false
                    })
                }
            }).catch((error) => {
                if (error.response.data.errors)
                    this.errors = error.response.data.errors
            }).finally(() => {
                if (!successResponse)
                    this.loading = false
            })
        },
        showPasswordResetForm(){
          this.showPasswordReset = true
        },
        sendNewPassword() {
          console.log('sendNewPassword')
          if (this.sendPasswordLoading)
            return false;
          useRecaptcha((token) => {
            this.sendPasswordLoading = true
            this.timerCount = 50
            this.$axios.post('auth/send-new-password', {
              token: token,
              iin: this.form.iin,
              phone: this.form.phone
            }).then(() => {
                this.timerEnabled = true
                this.showPasswordReset = false
            }).catch(error => {
              if (error.response.data.errors)
                this.errors = error.response.data.errors
              if (error.response.data.message)
                this.errors = {
                  password: [error.response.data.message]
                }
            }).finally(() => {
              this.sendPasswordLoading = false
            })
          })


        },
        reset() {
            Object.assign(this.$data, initialState());
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        btsRedirect() {
            // в ios открываем через InAppBrowser
            if (this.mobileApp.isIosWV()) {
                Browser.open({url: this.btsRedirectUrl});
            } else {
                window.open(this.btsRedirectUrl, '_self')
            }
            this.$bvModal.hide('confirmBtsModal')
        },
        async veriliveRedirect() {
            this.setIinForVerilive(this.form.iin)
            this.$bvModal.hide('confirmVeriliveModal')
            await this.$router.push({name: 'biometry'})
        },
        async kdpShow() {
            this.loading = true
            this.errors = {}

            this.$store.commit('kdp/SET_IIN', this.form.iin)
            this.$store.commit('kdp/SET_PHONE', this.form.phone)

            await this.$store.dispatch('kdp/verifyGbdIinPhone')

            if (this.$store.getters['kdp/getErrorResponse']) {

                if (this.$store.getters["kdp/getResponseCode"] === 400) {
                    this.errors = {"phone_number": this.$store.getters['kdp/getErrorResponse']}
                    this.loading = false
                    return
                }

                this.message = 'KDP_CHECK_ERROR'
                this.$bvModal.show('kdpError');
                this.loading = false
                return
            }

            // Если телефона нету в БМГ то клиент не сможет пройти КДП
            if (!this.$store.getters['kdp/getVerifyPhone']) {
                this.phoneEntered()
                return
            }

            await this.$store.dispatch('kdp/kdpCheck')

            if (this.$store.getters['kdp/getErrorResponse']) {
                this.message = 'KDP_CHECK_ERROR'
                this.$bvModal.show('kdpError');
                this.loading = false
                return
            }

            if (this.$store.getters["kdp/getHasSuccessToken"]) {
                this.phoneEntered()
            } else {
                await this.$router.push({name: 'kdp'})
                this.loading = false
            }

        },
        checkKdp() {
            if (this.$store.getters["kdp/getShowThirdStepOfLoginPage"]) {
                this.showSecondStep = true
                this.profileChecked = true
                this.phoneLogInConfirmed = true
                this.$store.dispatch('kdp/setShowThirdStepOfLoginPage', false)
                this.$store.dispatch('kdp/setProfileChecked', false)

                this.form.iin = this.$store.getters['kdp/getIIn']
                this.form.phone = this.$store.getters['kdp/getPhone']

                this.phoneEntered()
            }
        },
        phoneEntered() {
            this.loading = true
            this.errors = {}
            this.$axios.post('/auth/signup', {
                iin: this.form.iin,
                phone_number: this.form.phone,
            }).then((response) => {
                if (response.data.success) {
                    this.showSecondStep = false
                    this.message = 'We have sent an SMS with a verification code'
                    this.profileChecked = true
                    this.profileExists = true
                } else {
                    if(response.data.error === 'BMG verify fail'){
                        this.message = response.data.error
                    } else {
                        this.message = 'SMS sending error. Please try later.'
                    }
                }
                this.$bvModal.show('messageModal');
            }).catch((e) => {
                console.error(e)
                this.errors = e.response.data.errors || {}
            }).finally(() => {
                this.loading = false
            })
        },
        getTypeOfBiometry() {
            const bioSystem = Cookies.get('biometric_system')
            if (bioSystem) {
                this.biometricSystem = parseInt(bioSystem)
                this.veriliveCheck()
                return
            }

            this.$axios.get('/get/active-biometry').then((response) => {
                if (response.data.data.bts) {
                    this.biometricSystem = this.constants.verification.bts
                } else if (response.data.data.verigram) {
                    this.biometricSystem = this.constants.verification.verilive
                } else {
                    this.biometricSystem = this.constants.verification.bts
                }
            }).catch((e) => {
                console.error(e)
            }).finally(() => {
                Cookies.set('biometric_system', this.biometricSystem, {expires: (1 / 1440) * 10})
                this.veriliveCheck()

                if (this.mobileApp.isIosWV() && this.biometricSystem === this.constants.verification.bts) {
                    echoInstance.channel('bts.redirect.login').listen('BTSRedirectedBackToLogin', e => {
                        if (e.iin === this.form.iin) {
                            Browser.close()
                        }
                    })
                }
            })
        },
        veriliveCheck() {
            // проверяем флаг от вериграм, если он установлен то пропускаем шаг ввода иин-а и показываем ввод тел номера
            if (this.biometricSystem === this.constants.verification.verilive) {
                if (this.$store.getters["verilive/showSecondStepOfLoginPage"]) {
                    this.showSecondStep = true
                    this.profileChecked = true
                    this.phoneLogInConfirmed = true
                    this.setShowSecondStepOfLoginPage(false)
                    this.$store.dispatch('kdp/setProfileChecked', true)
                }
                if (this.$store.getters["verilive/iin"]) {
                    this.form.iin = this.$store.getters["verilive/iin"]
                    this.setIinForVerilive(null)
                }
            }
        }
    },
    computed: {
        showFirstStep() {
            return !this.profileChecked || (!this.phoneLogInConfirmed && !this.profileExists)
        },
        showThirdStep() {
            return (this.profileChecked && this.profileExists && !this.showPasswordReset)
        }
    },
    watch: {
        'form.iin'() {
            this.errors = {}
        },
        'form.phone'() {
            this.errors = {}
        },
        'form.password'() {
            this.errors = {}
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false
                }
            }
        },
        showPasswordExpiredModal(value) {
            if (value)
                this.form.password = ''
        }
    },
    updated() {
        this.$nextTick(() => {
            if (this.$refs["password-input"])
                this.$refs["password-input"].focus()
        })
    },
    mounted() {
        this.getTypeOfBiometry()
        this.checkKdp()

    let query = this.$route.query
    if (typeof query.registration !== 'undefined') {
      this.message = 'SMS sending error. Please try later.'
      if (query.registration === 'true') {
        this.message = 'We have sent an SMS with a verification code'
      }
      this.$bvModal.show('messageModal');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/authorization";
</style>
