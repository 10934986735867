<template>
    <div class="allCallsHistory">
        <div class="callHistory-container">
            <div class="callHistory-header">
                <router-link to="/"><a class="link-back">Карта клиента</a></router-link>
                <div class="callHistory__title-search-call">
                    <div class="left"><h3 class="callHistory-title">История звонков</h3></div>
                    <div class="right">
                        <div class="input-with-btn">
                            <input v-model="searchInCallHistoryText" maxlength="20" type="text" class="input" @keyup.enter="searchEverywhere" placeholder="Поиск">
<!--                            <button  v-if="searchInCallHistoryText!==''" class="btn-clear-input" @keyup.enter.prevent @click="searchInCallHistoryText=''"></button>-->
                        </div>
                        <button v-if="searchInCallHistoryText!==''" class="btn-clear-input" @mousedown="clearSearchInCallHistoryText"></button>
                        <div class="callHistory-excel"
                             :style="{cursor: downloadExcelInProcess ? 'not-allowed' : ''}"
                             @click="downloadExcelReport">
                            <div class="callHistory-excel_icon"></div>
                            Выгрузить отчет в MS Excel
                        </div>
                    </div>
                </div>
                <div class="callHistory__title-search-call">
                    <div class="left"></div>
                    <div class="right">
                        <h4 :class="!isEmptyFilter ? 'active-reset-btn' : 'inactive-reset-btn'"
                            @click="clearFilters"
                            class="callHistory-excel">
                            Сбросить фильтры
                        </h4>
                    </div>
                </div>
            </div>
            <div v-if="searchInCallHistoryText && false" class="no-found-block no-found-inCallHistory">
                <p class="no-found-text">По запросу <span>{{searchInCallHistoryText}}</span> ничего не найдено.</p>
            </div>
            <div class="table-wrapper">
                <div class="rights-blocker" v-if="mainLoading">
                    <b-spinner />
                </div>
                <div class="callHistory-body" @scroll="autoLoadScroll">
                    <div class="callHistory__table-param-container">
                        <div ref="paramsTitleRef" class="callHistory__table-param">
                            <div v-for="(item, index) in callHistoryParameters"
                                 :key="index"
                                 :class="[item.class, {'fixed-column' : item.class==='editHistory'}]"
                                 class="callHistory__table-params-item">
                                {{item.title}}
                                <div v-if="item.filter">
                                    <b-dropdown v-if="index === 'callDate'" variant="link" offset="[0px,11px]" :ref="index" toggle-class="text-decoration-none" no-caret class="filterMenu-wrapper">
                                        <template #button-content>
                                            <div class="filterParam"></div>
                                        </template>
                                        <div class="filter-title">Фильтр</div>
                                        <form class="inputFilter-container"  action="javascript:;">
                                            <div class="datepicker-wrapper">
                                                <label for="startDate">C </label>
                                                <flat-pickr
                                                    id="startDate"
                                                    v-model="item.selected.start"
                                                    :config="configDate"
                                                    class="inputFilterDate"
                                                    placeholder="дд.мм.гггг"
                                                />
                                            </div>
                                            <div class="datepicker-wrapper">
                                                <label for="endDate">По </label>
                                                <flat-pickr
                                                    id="endDate"
                                                    v-model="item.selected.end"
                                                    :config="configDate"
                                                    class="inputFilterDate"
                                                    placeholder="дд.мм.гггг"
                                                />
                                            </div>
                                        </form>
                                        <div class="filter-items" v-if="item.options && item.options.length > 0">
                                            <div class="filter-items-scroll">
                                                <b-form-group v-slot="{ ariaDescribedby }" class="filterCheckbox">
                                                    <b-form-checkbox-group
                                                        @click.native.capture.stop
                                                        v-model="item.selected"
                                                        :options="item.options"
                                                        :aria-describedby="ariaDescribedby"
                                                    ></b-form-checkbox-group>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="filter-btns">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="applyFilters(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                    </b-dropdown>
                                    <b-dropdown v-else variant="link" offset="[0px,11px]" :ref="index" toggle-class="text-decoration-none" no-caret class="filterMenu-wrapper">
                                        <template #button-content>
                                            <div class="filterParam"></div>
                                        </template>
                                        <div class="filter-title">Фильтр</div>
                                        <form class="inputFilter-container" v-if="index === 'operator'"  action="javascript:;">
                                            <input type="text" v-model="item.searchText" class="inputFilter" placeholder="Поиск">
                                        </form>
                                        <form class="inputFilter-container" v-else-if="index === 'client'"  action="javascript:;">
                                            <input type="text" v-model="item.searchText" class="inputFilter" placeholder="Поиск">
                                        </form>
                                        <form class="inputFilter-container" v-else-if="index === 'iin'"  action="javascript:;">
                                            <input type="text" v-model="item.searchText" class="inputFilter" placeholder="Поиск">
                                        </form>
                                        <form class="inputFilter-container" v-else-if="index === 'bin'"  action="javascript:;">
                                            <input type="text" v-model="item.searchText" class="inputFilter" placeholder="Поиск">
                                        </form>
                                        <form class="inputFilter-container" v-else-if="index === 'callTheme'"  action="javascript:;"></form>
                                        <form class="inputFilter-container" v-else-if="index === 'callSubTheme'"  action="javascript:;"
                                        ></form>
                                        <form class="inputFilter-container" v-else-if="index === 'status'"  action="javascript:;"></form>
                                        <div class="filter-items" v-if="item.options && item.options.length > 0">
                                            <div class="filter-items-scroll">
                                                <b-form-group v-slot="{ ariaDescribedby }" class="filterCheckbox">
                                                    <b-form-checkbox-group
                                                        @click.native.capture.stop
                                                        v-model="item.selected"
                                                        :options="item.options"
                                                        :aria-describedby="ariaDescribedby"
                                                    ></b-form-checkbox-group>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="filter-btns" v-if="index === 'operator'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="operatorSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                        <div class="filter-btns" v-else-if="index === 'client'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="clientSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                        <div class="filter-btns" v-else-if="index === 'iin'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="iinSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                        <div class="filter-btns" v-else-if="index === 'bin'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="binSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                        <div class="filter-btns" v-else-if="index === 'callTheme'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="callThemeSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                        <div class="filter-btns" v-else-if="index === 'callSubTheme'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="callSubThemeSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                        <div class="filter-btns" v-else-if="index === 'status'">
                                            <div class="filter-btns__cancel" @click="hideFilter(index)"><span>Отмена</span></div>
                                            <div class="filter-btns__ok" @click="statusSelected(); hideFilter(index)"><span>Ок</span></div>
                                        </div>
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="callHistory__table-values">
                        <div class="callHistory__table-values-item" v-for="(item, index) in callHistoryValues"
                             :key="item.uniqueId"
                             :class="{'editing-row': updateCallDataStruct.editingRowId === item.uniqueId}">
                            <div class="editHistory fixed-column center"
                                 :class="{'actions': updateCallDataStruct.editingRowId === item.uniqueId}">
                                <template v-if="updateCallDataStruct.editingRowId === item.uniqueId">
                                    <template v-if="updateCallDataLoading">
                                        <b-spinner small variant="secondary" v-if="updateCallDataLoading"
                                                   class="spinner-border-sm"/>
                                    </template>
                                    <template v-else>
                                        <div @click="updateCallData" class="nui-icon nui-icon-check-mark"></div>
                                        <div @click="cancelEditCallData" class="nui-icon nui-icon-x-mark"></div>
                                    </template>
                                </template>
                                <div v-else class="nui-icon nui-icon-pen" @click="clickedEditCallData(item)"></div>
                            </div>
                            <div :class="item.callDate.class">
                                <div class="callDateHistory__container">
                                    <div class="callDateHistory__phone">
                                        <div class="callDateHistory__phone-icon" @click="initiateCall(item.client.value)"></div>
                                    </div>
                                    <div class="callDateHistory__container-title">{{item.callDate.value}}</div>
                                </div>
                            </div>
                            <div :class="item.operator.class">{{item.operator.value}}</div>
                            <div :class="item.client.class">{{item.client.value}}</div>
                            <div :class="item.iin.class">
                                <template v-if="updateCallDataStruct.editingRowId === item.uniqueId">
                                    <the-mask class="squared-input"
                                              placeholder="ИИН"
                                              v-model="updateCallDataStruct.iin"
                                              :mask="['############']"/>
                                </template>
                                <template v-else>
                                    {{item.iin.value}}
                                </template>
                            </div>
                            <div :class="item.fio.class">
                                <template v-if="updateCallDataStruct.editingRowId === item.uniqueId">
                                    <input class="squared-input"
                                           placeholder="ФИО"
                                           v-model="updateCallDataStruct.fio"/>
                                </template>
                                <template v-else>
                                    {{ item.fio.value }}
                                </template>
                            </div>
                            <div :class="item.bin.class">
                                <template v-if="updateCallDataStruct.editingRowId === item.uniqueId">
                                    <the-mask class="squared-input"
                                              placeholder="БИН"
                                              v-model="updateCallDataStruct.bin"
                                              :mask="['############']"/>
                                </template>
                                <template v-else>
                                    {{item.bin.value}}
                                </template>
                            </div>
                            <div :class="item.callTheme.class">
                                <template v-if="updateCallDataStruct.editingRowId === item.uniqueId">
                                    {{ updateCallDataStruct.theme }}
                                </template>
                                <template v-else>
                                    {{ item.callTheme.value }}
                                </template>
                            </div>
                            <div :class="item.callSubTheme.class">
                                <CallThemeSelect v-if="updateCallDataStruct.editingRowId === item.uniqueId"
                                                 @change="updateCallDataThemeChanged"
                                                 size="sm"
                                                 class="incomingClient-param--select"
                                                 :preset-main-topic="item.callTheme.value"
                                                 :preset-value="item.callSubTheme.value"
                                />
                                <template v-else>
                                    {{item.callSubTheme.value}}
                                </template>
                            </div>
                            <div :class="item.comments.class">
                                <template v-if="updateCallDataStruct.editingRowId === item.uniqueId">
                                    <textarea cols="30" rows="4"
                                              v-model="updateCallDataStruct.comment"
                                              class="squared-input"
                                    ></textarea>
                                </template>
                                <template v-else>
                                    <div class="commentsHistorySize box" :class="item.comments.readMoreBtn ? 'open' : ''">
                                        <div class="text" :class="item.comments.readMoreBtn ? 'open' : ''">
                                            {{item.comments.value}}
                                        </div>
                                    </div>
                                    <div v-if="item.comments.value.length > 70"
                                         @click="item.comments.readMoreBtn = !item.comments.readMoreBtn"
                                         class="readMoreComment">
                                        {{item.comments.readMoreBtn ? 'Скрыть' : 'Читать полностью'}}
                                    </div>
                                </template>
                            </div>
                            <div :class="item.status.class">{{ $t(`${item.callType}.${item.status.value}`) }}</div>
                            <div :class="item.record.class" :key="item.uniqueId">
                                <AudioPlayer
                                    v-if="item.fileName!==''"
                                    :key="item.uniqueId"
                                    :playerIndex="index"
                                    :isThisPlayerActive="pressedPlay === index"
                                    :callDate="item.callDate.value"
                                    :fileName="item.fileName"
                                    :isLoadingAudio="isLoadingAudio"
                                    :duration="item.duration"
                                    @playPressed="pressedPlay = $event">
                                </AudioPlayer>
                            </div>
                        </div>

                        <AlertInfoText/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AudioPlayer from "@/components/operator/AudioPlayer";
import {mapActions} from "vuex";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import {echoInstance} from "@/plugins/echo";
import {TheMask} from 'vue-the-mask'
import CallThemeSelect from '@/components/operator/CallThemeSelect'
import AlertInfoText from "@/components/modals/AlertInfoText";

export default {
    name: "allCallHistory",
    props:['heightHeaderAndMain'],
    components:{
        AlertInfoText,
        AudioPlayer,
        flatPickr,
        TheMask,
        CallThemeSelect
    },
    data: function() {
        return {
            pressedPlay: false,
            audioForPlayer: '',
            searchInCallHistoryText: '',
            selected: {
                date: {
                    start: '',
                    end: ''
                },
                operators: [],
                clients: [],
                iins: [],
                bins: [],
                call_themes: [],
                call_sub_themes: [],
                statuses: [],
                start_from: 0,
                load_count: this.loadCount
            },
            callHistoryParameters: {
                edit:{
                    title: 'Изменить',
                    class: 'editHistory',
                    options: [],
                    filter: false
                },
                callDate:{
                    title: 'Дата звонка',
                    class: 'callDateHistory',
                    filter: true,
                    options: [],
                    selected: {
                        start: '',
                        end: ''
                    }
                },
                operator:{
                    title: 'Оператор',
                    class: 'operatorHistory',
                    filter: true,
                    options: [],
                    selected: [],
                    searchText: ''
                },
                client:{
                    title: 'Клиент',
                    class: 'clientHistory',
                    filter: true,
                    options: [],
                    selected: [],
                    searchText: ''
                },
                iin:{
                    title: 'ИИН',
                    class: 'iinHistory',
                    filter: true,
                    options: [],
                    selected: [],
                    searchText: ''
                },
                fio:{
                    title: 'ФИО',
                    class: 'fioHistory',
                    filter: false,
                    options: [],
                    selected: [],
                    searchText: ''
                },
                bin:{
                    title: 'БИН',
                    class: 'binHistory',
                    filter: true,
                    options: [],
                    selected: [],
                    searchText: ''
                },
                callTheme:{
                    title: 'Тема обращения',
                    class: 'callThemeHistory',
                    filter: true,
                    options: [],
                    selected: []
                },
                callSubTheme:{
                    title: 'Подтема обращения',
                    class: 'callSubThemeHistory',
                    filter: true,
                    options: [],
                    selected: []
                },
                comments:{
                    title: 'Комментарий',
                    class: 'commentsHistory',
                    options: [],
                    filter: false
                },
                status:{
                    title: 'Статус',
                    class: 'statusHistory',
                    filter: true,
                    options: [],
                    selected: []
                },
                record:{
                    title: 'Запись',
                    class: 'recordHistory',
                    options: [],
                    filter: false
                },
            },
            callHistoryValues: [],
            isLoadingAudio: true,

            box: '',
            rowsCount: '',
            rowHeight: '',
            boxHeight: '',
            autoLoadProcess: false,

            searchEverywhereInProcess: false,

            downloadExcelInProcess: false,

            page: 1,
            startFrom: 0,
            loadCount: 20,
            mainLoading: false,
            incrementStartFrom: true,

            filteredStage: false,
            searchStage: false,

            startFromFilter: 0,
            highlightApplyFilters: false,
            lastFilterRequestHash: '',
            isEmptyFilter: true,
            endOfRecords: false,
            configDate: {
                wrap: false,
                altFormat: 'd.m.Y',
                altInput: true,
                allowInput: true,
                dateFormat: 'd.m.Y',
                maxDate: new Date(),
                position: 'auto right',
                static: true,
                shorthandCurrentMonth: true,
                locale: Russian
            },

            isRowEditing: false,
            updateCallDataStruct: {
                editingRowId: null,
                call_id: null,
                iin: null,
                fio: null,
                bin: null,
                theme: null,
                subTheme: null,
                comment: null
            },
            updateCallDataLoading: false,
        }
    },
    methods: {
        ...mapActions('calls', ['setPhoneConfirmCall']),
        initiateCall(phone) {
            //показываем попап с подтверждением
            this.setPhoneConfirmCall(phone)
            this.$bvModal.show('confirm-call-modal')
        },
        getFilters(appliedFilter) {

            let filters = JSON.parse(JSON.stringify(this.selected))

            for (const key of Object.keys(filters)) {
                if (key !== appliedFilter && key !== 'date') {
                    filters[key] = []
                }
            }

            this.mainLoading = true
            this.$axios.get('/asterisk/get-filters', {
                params: filters
            })
                .then((response) => {
                    if (appliedFilter !== 'operators')
                        this.callHistoryParameters.operator.options = response.data.filters.operators

                    if (appliedFilter !== 'call_themes')
                        this.callHistoryParameters.callTheme.options = response.data.filters.callTheme

                    if (appliedFilter !== 'call_sub_themes')
                        this.callHistoryParameters.callSubTheme.options = response.data.filters.callSubTheme

                    if (appliedFilter !== 'statuses') {
                        this.callHistoryParameters.status.options = []
                        for (let i = 0; i < response.data.filters.callTypes.length; i++) {
                            let obj = {
                                text: this.$t(response.data.filters.callTypes[i]),
                                value: response.data.filters.callTypes[i]
                            }
                            this.callHistoryParameters.status.options.push(obj)
                        }
                    }
                    this.mainLoading = false
                    this.applyFilters(false)
                })
        },
        fetchRecords() {
            if(!this.autoLoadProcess) {
                this.autoLoadProcess = true
                this.mainLoading = true
                this.$axios.get('/asterisk/get/record/all', {
                    params: {
                        start_from: (this.page - 1) * this.loadCount,
                        load_count: this.loadCount
                    }
                }).then(response => {
                    if (response.data.records.length > 0) {
                        if (this.page === 1)
                            this.callHistoryValues = []

                        this.transformResponseData(response.data.records)

                        /*if (this.startFrom === 0)
                            this.scrollToTopBox()*/
                        this.page += 1
                    } else {
                        // eslint-disable-next-line no-undef
                        system_message_popup('Ничего не найдено :(')
                        this.endOfRecords = true
                    }
                    this.isLoadingAudio = false
                    this.autoLoadProcess = false
                    this.lastFilterRequestHash = JSON.stringify(this.selected)
                    /*if(this.incrementStartFrom)
                        this.startFrom += this.loadCount
                    else
                        this.incrementStartFrom = true
                    this.autoLoadProcess = false
                    this.lastFilterRequestHash = JSON.stringify(this.selected)*/
                }).finally(() => {
                    this.mainLoading = false
                })
            }
        },
        operatorSelected() {
            let operatorsArr = this.callHistoryParameters.operator.searchText.replaceAll(/\s/g, '').replaceAll(/,+/g, ',').split(',')
            operatorsArr.forEach(elem => {
                if (elem !== '') {
                    this.callHistoryParameters.operator.selected.push(elem)
                    this.callHistoryParameters.operator.options.unshift(elem)
                }
            })
            this.callHistoryParameters.operator.selected = new Set(this.callHistoryParameters.operator.selected)
            this.callHistoryParameters.operator.selected = Array.from(this.callHistoryParameters.operator.selected)

            this.callHistoryParameters.operator.searchText = ''
            this.getFilters('operators')
        },
        clientSelected() {
            let clientsArr = this.callHistoryParameters.client.searchText.replaceAll(/\s/g, '').replaceAll(/,+/g, ',').split(',')
            clientsArr.forEach(elem => {
                if (elem !== '') {
                    this.callHistoryParameters.client.selected.push(elem)
                    this.callHistoryParameters.client.options.unshift(elem)
                }
            })
            this.callHistoryParameters.client.selected = new Set(this.callHistoryParameters.client.selected)
            this.callHistoryParameters.client.selected = Array.from(this.callHistoryParameters.client.selected)

            this.callHistoryParameters.client.searchText = ''
            this.getFilters('clients')
        },
        iinSelected() {
            let iinsArr = this.callHistoryParameters.iin.searchText.replaceAll(/\s/g, '').replaceAll(/,+/g, ',').split(',')
            iinsArr.forEach(elem => {
                if (elem !== '') {
                    this.callHistoryParameters.iin.selected.push(elem)
                    this.callHistoryParameters.iin.options.unshift(elem)
                }
            })
            this.callHistoryParameters.iin.selected = new Set(this.callHistoryParameters.iin.selected)
            this.callHistoryParameters.iin.selected = Array.from(this.callHistoryParameters.iin.selected)

            this.callHistoryParameters.iin.searchText = ''
            this.getFilters('iins')
        },
        binSelected() {
            let binsArr = this.callHistoryParameters.bin.searchText.replaceAll(/\s/g, '').replaceAll(/,+/g, ',').split(',')
            binsArr.forEach(elem => {
                if (elem !== '') {
                    this.callHistoryParameters.bin.selected.push(elem)
                    this.callHistoryParameters.bin.options.unshift(elem)
                }
            })
            this.callHistoryParameters.bin.selected = new Set(this.callHistoryParameters.bin.selected)
            this.callHistoryParameters.bin.selected = Array.from(this.callHistoryParameters.bin.selected)

            this.callHistoryParameters.bin.searchText = ''
            this.getFilters('bins')
        },
        callThemeSelected() {
            this.getFilters('call_themes')
        },
        callSubThemeSelected() {
            this.getFilters('call_sub_themes')
        },
        statusSelected() {
            this.getFilters('statuses')
        },
        hideFilter(index) {
            this.$refs[index][0].hide()
        },
        applyFilters(paginate) {
            /*if (this.isEmptyFilter) {
                console.log('block of applying, if filter is empty')
                return
            }*/

            this.lastFilterRequestHash = JSON.stringify(this.selected)
            if (!this.autoLoadProcess && !this.endOfRecords) {

                if (!paginate) {
                    this.page = 1
                }

                this.selected.start_from = (this.page - 1) * this.selected.load_count

                this.autoLoadProcess = true
                this.mainLoading = true
                this.filteredStage = true

                this.$axios.post('/asterisk/get/record/filter', this.selected).then(response => {
                    if (response.data.records.length > 0) {
                        if (!paginate)
                            this.callHistoryValues = []

                        this.transformResponseData(response.data.records)

                        if (this.startFromFilter === 0)
                            this.scrollToTopBox()

                        this.startFromFilter += this.loadCount
                        this.selected.start_from = this.startFromFilter

                        this.lastFilterRequestHash = JSON.stringify(this.selected)
                        this.page += 1

                    } else {
                        // eslint-disable-next-line no-undef
                        system_message_popup('Ничего не найдено :(')
                        this.endOfRecords = true
                    }
                    this.isLoadingAudio = false
                    this.autoLoadProcess = false
                }).finally(() => {
                    this.mainLoading = false
                })
            }
        },
        clearFilters() {
            this.callHistoryParameters.callDate.selected.start = ''
            this.callHistoryParameters.callDate.selected.end = new Date().toLocaleDateString("ru-RU")
            this.callHistoryParameters.operator.selected = []
            this.callHistoryParameters.client.selected = []
            this.callHistoryParameters.iin.selected = []
            this.callHistoryParameters.bin.selected = []
            this.callHistoryParameters.callTheme.selected = []
            this.callHistoryParameters.callSubTheme.selected = []
            this.callHistoryParameters.status.selected = []
            this.selected = {
                date: this.callHistoryParameters.callDate.selected,
                operators: this.callHistoryParameters.operator.selected,
                clients: this.callHistoryParameters.client.selected,
                iins: this.callHistoryParameters.iin.selected,
                bins: this.callHistoryParameters.bin.selected,
                call_themes: this.callHistoryParameters.callTheme.selected,
                call_sub_themes: this.callHistoryParameters.callSubTheme.selected,
                statuses: this.callHistoryParameters.status.selected,
                start_from: this.startFromFilter,
                load_count: this.loadCount
            }
            if (this.filteredStage || this.searchStage) {
                this.filteredStage = false
                this.searchStage = false
                this.startFromFilter = 0
                this.startFrom = 0
                this.page = 1
                this.getFilters('')
                this.fetchRecords()
            }
        },
        initAutoLoading() {
            /* eslint-disable */
            this.box = $('.callHistory-body')
            this.rowsCount = $('.callHistory__table-values-item').length
            this.rowHeight = $('.callHistory__table-values-item').height()
            this.boxHeight = this.rowsCount * this.rowHeight
        },
        autoLoadScroll() {
            if ($(this.box).scrollTop() + $(this.box).height() >= this.boxHeight - 200 && !this.autoLoadProcess && !this.searchStage && this.boxHeight > 500) {
                console.log('Автоподгрузка...')
                if (this.filteredStage)
                    this.applyFilters(true)
                else
                    this.fetchRecords()
            }
        },
        scrollToTopBox() {
            $(this.box).scrollTop(0)
        },
        downloadExcelReport() {
            let ob = {
                start_from: 0,
                filter: this.filteredStage
            }
            if (this.filteredStage) {
                ob.filter_data = this.selected
                ob.filter_data.start_from = 0;
            }
            if (!this.downloadExcelInProcess) {
                this.downloadExcelInProcess = true
                this.mainLoading = true
                this.$axios.post('/asterisk/record/download/excel', ob, {
                    responseType: 'blob',
                }).then(response => {
                    const filename = 'История звонков.xlsx';
                    let blob = new Blob([response.data], {
                        type: 'application/octet-stream',
                    });

                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        let blobURL = window.URL.createObjectURL(blob);
                        let tempLink = document.createElement('a');
                        tempLink.style.display = 'none';
                        tempLink.href = blobURL;
                        tempLink.download = filename;
                        tempLink.click();
                        window.URL.revokeObjectURL(blobURL);
                    }
                }).finally(() => {
                    this.mainLoading = false
                    this.downloadExcelInProcess = false
                })
            }
        },
        searchEverywhere() {
            this.selected.date.start = ''
            this.selected.date.end = ''
            this.selected.operators.length = 0
            this.selected.clients.length = 0
            this.selected.iins.length = 0
            this.selected.bins.length = 0
            this.selected.call_themes.length = 0
            this.selected.call_sub_themes.length = 0
            this.selected.statuses.length = 0
            this.isEmptyFilter = true

            this.searchStage = true
            this.mainLoading = true
            if (!this.searchEverywhereInProcess) {
                this.searchEverywhereInProcess = true
                this.$axios.get('/asterisk/record/history/search', {
                    params: {
                        fragment: this.searchInCallHistoryText
                    }
                }).then(response => {
                    this.searchEverywhereInProcess = false
                    if (response.data.success) {
                        this.callHistoryValues = []

                        this.transformResponseData(response.data.data)

                        this.scrollToTopBox()
                        this.lastFilterRequestHash = JSON.stringify(this.selected)
                    } else {
                        // eslint-disable-next-line no-undef
                        system_message_popup(response.data.message)
                        this.endOfRecords = true
                    }
                }).finally(() => {
                    this.mainLoading = false
                })
            }
        },
        clearSearchInCallHistoryText() {
            this.searchInCallHistoryText = ''
            this.clearFilters()
        },
        getFormattedCallDate(callDate) {
            // from 2022-10-13 12:58:04 to 13.10.2022 12:58:04
            let dateTimeParts = callDate.split(' ')
            let dateParts = dateTimeParts[0].split('-')
            return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]} ${dateTimeParts[1]}`
        },
        transformResponseData(records) {
            for (let i = 0; i < records.length; i++) {
                let duration = +records[i].duration
                for (let j = 0; j < records.length; j++) {
                    if(i === j)
                        continue
                    if(records[i].filename === records[j].filename) {
                        duration += +records[j].duration
                    }
                }
                let obj = {
                    callDate: {
                        value: this.getFormattedCallDate(records[i].calldate),
                        class: 'callDateHistory'
                    },
                    operator: {
                        value: '',
                        class: 'operatorHistory'
                    },
                    client: {
                        value: '',
                        class: 'clientHistory'
                    },
                    iin: {
                        value: records[i].iin ?? '',
                        class: 'iinHistory'
                    },
                    fio: {
                        value: records[i].fio ?? '',
                        class: 'fioHistory'
                    },
                    bin: {
                        value: records[i].bin ?? '',
                        class: 'binHistory'
                    },
                    callTheme: {
                        value: records[i].call_topic ?? '',
                        class: 'callThemeHistory'
                    },
                    callSubTheme: {
                        value: records[i].call_sub_topic ?? '',
                        class: 'callSubThemeHistory'
                    },
                    comments: {
                        value: records[i].call_comment ?? '',
                        class: 'commentsHistory',
                        readMoreBtn: false
                    },
                    status: {
                        value: records[i].disposition,
                        class: 'statusHistory'
                    },
                    record: {
                        currentTime: '0:00',
                        endTime: '5:45',
                        class: 'recordHistory'
                    },
                    callType: records[i].calltype,
                    fileName: records[i].filename,
                    call_id: records[i].call_id,
                    duration: duration,
                    uniqueId: records[i].uniqueid,
                }
                obj.operator.value=records[i].dst;
                obj.client.value=records[i].src;
                if (records[i].dst.length < 5) {
                    if(records[i].dstSip!==undefined&&records[i].dstSip!==records[i].dst){
                        let dst=records[i].dstSip;
                        if(records[i].cnumSip!==''&&records[i].cnumSip!==undefined&&records[i].dstSip!==records[i].cnumSip){
                          dst=records[i].cnumSip
                        }
                        obj.operator.value +=' - '+ dst;
                    }
                    if(records[i].userName===undefined||records[i].userName===''){
                      if(records[i].userNameTo!==undefined&&records[i].userNameTo!==''){
                        obj.operator.value +=' - '+ records[i].userNameTo;
                      }
                    }else{
                      if(records[i].userName!==undefined&&records[i].userName!==''){
                        obj.operator.value +=' - '+ records[i].userName;
                      }
                    }

                }
                if (records[i].src.length < 5){
                    if(records[i].cnumSip!==undefined&&records[i].cnumSip!==records[i].src){
                        obj.client.value +=' - '+ records[i].cnumSip;
                    }
                    if(records[i].userName!==undefined&&records[i].userName!==''){
                        obj.client.value +=' - '+ records[i].userName;
                    }
                }
                this.callHistoryValues.push(obj)
            }
        },
        initAutoUpdate() {
            echoInstance.connector.options.auth.headers['Authorization'] = 'Bearer ' + this.$store.getters['auth/token']
            echoInstance.private('call.ringing')
                .listen('CallRingingUp', () => {
                    this.dynamicUpdate()
                })
            setInterval(this.dynamicUpdate, 40000)
        },
        dynamicUpdate() {
            if (!this.filteredStage && !this.searchStage) {
                let lCount = this.startFrom
                if(this.startFrom === 0)
                    lCount = 20
                this.$axios.get('/asterisk/get/record/all', {
                    params: {
                        start_from: 0,
                        load_count: lCount
                    }
                }).then(response => {
                    if (response.data.records.length > 0) {
                        if(response.data.records[0].uniqueid === this.callHistoryValues[0].uniqueId)
                            return
                        this.callHistoryValues = []

                        this.transformResponseData(response.data.records)

                        /*if (this.startFrom === 0)
                        this.scrollToTopBox()*/
                        // this.page += 1
                    } else {
                        // eslint-disable-next-line no-undef
                        system_message_popup('Ничего не найдено :(')
                        this.endOfRecords = true
                    }
                    this.lastFilterRequestHash = JSON.stringify(this.selected)
                    /*if(this.incrementStartFrom)
                    this.startFrom += this.loadCount
                else
                    this.incrementStartFrom = true
                this.autoLoadProcess = false
                this.lastFilterRequestHash = JSON.stringify(this.selected)*/
                })
            }
        },

        clickedEditCallData(item) {
            this.isRowEditing = true
            this.updateCallDataStruct = {
                editingRowId: item.uniqueId,
                call_id: item.call_id,
                iin: item.iin.value,
                fio: item.fio.value,
                bin: item.bin.value,
                theme: null,
                subTheme: null,
                comment: item.comments.value
            }
        },
        cancelEditCallData() {
            this.isRowEditing = false
            this.updateCallDataStruct = {
                editingRowId: null,
                call_id: null,
                iin: null,
                fio: null,
                bin: null,
                theme: null,
                subTheme: null,
                comment: null,
            }
        },
        ...mapActions({
            setAlertText: 'modalOptions/setAlertText'
        }),
        updateCallData() {
            this.updateCallDataLoading = true
            this.$axios.post('/operator/calls/update/data', this.updateCallDataStruct).then((response) => {
                if (response.data.success) {
                    for (let callHistoryValue of this.callHistoryValues) {
                        if (callHistoryValue.uniqueId === this.updateCallDataStruct.editingRowId) {
                            callHistoryValue.iin.value = this.updateCallDataStruct.iin
                            callHistoryValue.fio.value = this.updateCallDataStruct.fio
                            callHistoryValue.bin.value = this.updateCallDataStruct.bin
                            callHistoryValue.callTheme.value = this.updateCallDataStruct.theme
                            callHistoryValue.callSubTheme.value = this.updateCallDataStruct.subTheme
                            callHistoryValue.comments.value = this.updateCallDataStruct.comment
                            break
                        }
                    }

                    this.cancelEditCallData()
                } else {
                    this.setAlertText('Не удалось сохранить данные, повторите попытку')
                }
            }).catch(() => {
                this.setAlertText('Не удалось сохранить данные, повторите попытку')
            }).finally(() => {
                this.updateCallDataLoading = false
            })
        },
        updateCallDataThemeChanged(element) {
            this.updateCallDataStruct.theme = element.topic
            this.updateCallDataStruct.subTheme = element.subTopic
        }
    },
    watch: {
        callHistoryParameters: {
            handler() {
                this.endOfRecords = false
                this.selected = {
                    date: this.callHistoryParameters.callDate.selected,
                    operators: this.callHistoryParameters.operator.selected,
                    clients: this.callHistoryParameters.client.selected,
                    iins: this.callHistoryParameters.iin.selected,
                    bins: this.callHistoryParameters.bin.selected,
                    call_themes: this.callHistoryParameters.callTheme.selected,
                    call_sub_themes: this.callHistoryParameters.callSubTheme.selected,
                    statuses: this.callHistoryParameters.status.selected,
                    start_from: this.startFromFilter,
                    load_count: this.loadCount
                }
                this.highlightApplyFilters = JSON.stringify(this.selected) !== this.lastFilterRequestHash;

                if(
                    this.selected.date.start === '' && this.selected.date.end === new Date().toLocaleDateString("ru-RU")
                    && this.selected.operators.length === 0 && this.selected.clients.length === 0
                    && this.selected.iins.length === 0 && this.selected.bins.length === 0
                    && this.selected.call_themes.length === 0 && this.selected.call_sub_themes.length === 0
                    && this.selected.statuses.length === 0
                )
                    this.isEmptyFilter = true
                else
                    this.isEmptyFilter = false
            },
            deep: true
        }
    },
    created() {
        this.getFilters('')
        this.fetchRecords()
        this.callHistoryParameters.callDate.selected.end = this.dateHelper.today()
    },
    updated() {
        this.initAutoLoading()
    },
    mounted() {
        this.initAutoLoading()
        this.initAutoUpdate()
    }
}
</script>

<style scoped lang="scss">
.table-wrapper {
    position: relative
}
.datepicker-wrapper {
    #dateFilterEnd, #dateFilterStart {
        padding: 0 10px;
        width: 155px;
        height: 30px;
        border: 1px solid #D8D8D8;
        border-radius: 5px;
    }
}
.allCallsHistory{
    display: flex;
    flex-grow: 1;
    .callHistory-container{
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 20px 42px 0;
        overflow: hidden;
        .input {
            margin: 0;
            padding: 0 14px;
            width: 326px;
            height: 30px;
            border: 1px solid #ACB9CF;
            border-radius: 50px;
            font-size: 12px;
            background: url("~@/assets/images/search.svg") top 50% right 14px / auto no-repeat;

            &::placeholder {
                font-weight: 400;
                font-size: 12px;
                line-height: 1;
                color: #ACB9CF;
            }
        }
    }
    .callHistory-header{
        width: 100%;
        padding-bottom: 20px;
    }
    .callHistory__title-search-call{
        padding-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .inactive-reset-btn {
            cursor: default !important;
            color: #73758C;
        }
        .active-reset-btn {
            color: #EF7F22;
        }
        .active-reset-btn:hover {
            color: #FF7400;
        }
        .right{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .callHistory-excel{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 17px;
                font-size: 12px;
                .callHistory-excel_icon{
                    width: 26px;
                    height: 26px;
                    background-color: #EF7F22;
                    border-radius: 50%;
                    margin-right: 7px;
                    background-image: url("~@/assets/images/excel.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:hover{
                    .callHistory-phone_icon{
                        background-color: #FF7400;
                    }
                }
            }
            .hover-red:hover {
                color: #EB2626;
            }
        }
    }
    .callHistory-title{
        font-size: 20px;
        font-weight: 600;
    }
    .callHistory-body{
        border-radius: 5px 5px 0 0;
        background-color: #FFFFFF;
        width: 100%;
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar {
            width: 0;
            height: 15px;
        }
        &::-webkit-scrollbar-track {
            width: 0;
            height: 0;
            background-color: #FFFFFF;
        }
        &::-webkit-scrollbar-thumb {
            width: 0;
            height: 0;
            border: 3px solid #FFFFFF;
            background-color: #D9D9D9;
            border-radius: 50px;
        }

    }
    .callHistory__table-param-container{
        position: sticky;
        top: 0;
        z-index: 6;
    }
    .callHistory__table-param{
        display: flex;
        .callHistory__table-params-item{
            background-color: #FFFFFF;
            border-bottom: 1px solid #C5D4E9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 43px;
            padding: 0 15px;
            color: #8D90A9;
            font-weight: 600;
            font-size: 12px;
            .btn-group{
                z-index: 2;
                position: absolute;
                transform: translate(-60%, -50%);
            }
            &:not(:last-child){
                border-right: 1px solid #E8EAED;
            }
            &:last-child {
                border-left: 1px solid #E8EAED;
            }
            .filterParam{
                cursor: pointer;
                width: 18px;
                height: 10px;
                background: url("~@/assets/images/filterParam.svg") center / contain no-repeat;
            }
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .callHistory__table-values.no-results {
        align-items: center;
    }
    .callHistory__table-values{
        display: flex;
        flex-direction: column;
        -ms-overflow-style: none;
        height: calc(100vh - 290px);
        .callHistory__table-values-item{
            display: flex;
            color: #8D90A9;
            font-weight: 400;
            font-size: 14px;
            > div{
                padding: 15px 15px 10px;
                color: #000000;
                border-bottom: 1px solid #E8EAED;
                &:not(:last-child){
                    border-right: 1px solid #E8EAED;
                }
                &:last-child {
                    border-left: 1px solid #E8EAED;
                }
            }
            &:not(:last-child){
            }
        }
    }

    .callDateHistory{
        min-width: 16%;
        .callDateHistory__container{
            display: flex;
            width: fit-content;
            .callDateHistory__phone{
                min-width: 27px;
                height: 27px;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #EF7F22;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                top: -3px;
                .callDateHistory__phone-icon{
                    width: 1em;
                    height: 1em;
                    position: relative;
                    top: -1px;
                    z-index: 0;
                    border-radius: 50%;
                    background-image: url("~@/assets/images/phoneIcon.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
                &:hover{
                    background-color: #FF7400
                }
            }
        }
    }
    .operatorHistory{
        min-width: 16%;
    }
    .clientHistory{
        min-width: 16%;
    }
    .iinHistory{
        min-width: 13%;
    }
    .fioHistory{
        min-width: 15%;
    }
    .binHistory{
        min-width: 13%;
    }
    .callThemeHistory{
        min-width: 22%;
    }
    .callSubThemeHistory{
        min-width: 22%;
    }
    .commentsHistory{
        flex-grow: 1;
        min-width: 20%;
        .box {
            max-height: 5em;
            overflow: hidden;
            transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
        }
        .box.open {
            max-height: 100rem;
            transition: max-height 0.3s cubic-bezier(0.9, 0, 0.8, 0.2);
        }
        @keyframes open {
            from {
                line-clamp: 3;
                -webkit-line-clamp: 3;
            }
            to {
                line-clamp: initial;
                -webkit-line-clamp: initial;
            }
        }

        @keyframes close {
            from {
                line-clamp: initial;
                -webkit-line-clamp: initial;
            }
            to {
                line-clamp: 3;
                -webkit-line-clamp: 3;
            }
        }
        .text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            animation: close 0.1s linear 0.1s forwards;
        }
        .open .text {
            animation: open 0.1s linear 0s forwards;
        }
        .readMoreComment{
            cursor: pointer;
            color: #EF7F22;
            font-weight: 400;
            font-size: 10px;
        }
    }
    .statusHistory{
        min-width: 14%;
    }
    .recordHistory{
        font-size: 12px;
        min-width: 17%;
        display: flex;
    }
    .editHistory{
        min-width: 8%;
    }
    .fixed-column {
        position: sticky;
        left: 0;
        background-color: #ffffff;
        z-index: 5;
        transform: translateZ(0);

        .nui-icon-pen {
            height: 20px;
            cursor: pointer;
        }
    }
    .editing-row {
        > div {
            background-color: #FBFCFF;
        }
    }
    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .actions {
        justify-content: space-around;

        .spinner-border-sm {
            position: unset;
        }
    }
    .nui-icon-check-mark {
        width: 18px;
        background-color: $green-1;
        cursor: pointer;
    }
    .nui-icon-x-mark {
        width: 14px;
        background-color: $red-1;
        cursor: pointer;
    }
}
.no-found-block.no-found-inCallHistory{
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;
    .no-found-text{
        position: absolute;
        top: -30px;
    }
}
.btn-clear-input {
    background: no-repeat url(http://localhost:8080/img/icon-x.0fb51bb5.svg) center center/65% auto;
    /* display: block; */
    position: absolute;
    right: 284px;
    top: -405px;
    bottom: 0;
    margin: auto;
    height: 15px;
    width: 16px;
    border: 0;
    opacity: 0.7;
}

.filterMenu-wrapper .dropdown-menu {
    width: auto;

    .filter-items .filter-items-scroll {
        max-height: calc(40vh - 62px);
    }

    .filterCheckbox .custom-control-label {
        padding-right: 18px;
    }
}
</style>
